import React from "react";
import styles from "./styles.module.scss";

export default function AboutUs() {
  return (
    <div className={`wrapper ${styles.main}`}>
      <div className={styles.titleBlock}>
        <h1>
          <span>Наше мастерство</span>
          <span>превращае металл</span>
          <span>в произведения искусства</span>
        </h1>
      </div>
      <img style={styles.img} src={require("./assets/bg.jpg")} />
      <div className={styles.block}>
        <img style={styles.img} src={require("./assets/block1.jpg")} />
        <p>
          Кузнецы всегда пользовались особым почтением в народе. Их уважали за
          тяжелый, порою непосильный труд, совершаемый в экстремальных условиях.
          Славные представители этой многовековой профессии, являются
          хранителями старинных традиций и практических навыков их воплощения.
          Чтобы изготовить красивое и точное изделие, нужна максимальная
          внимательность, творческий потенциал и эстетические чувства.
          Художественная ковка в России не потеряла своей значимости и
          продолжает рождать настоящие творения искусства в мастерской
          «Arm-kovka».
        </p>
      </div>
      <div className={`${styles.block} ${styles.block2}`}>
        <p>
          Ассортимент нашей мастерской представлен оригинальными образцами,
          которые прекрасно впишутся в любой изысканный интерьер, сделают его
          еще красивее и роскошнее. Мастера-профессионалы вкладывают в каждое
          изделие любовь к своему делу, показывая высокий уровень квалификации и
          таланта в каждой детали. В наших коллекциях творимая настоящими
          опытными специалистами художественная ковка в Москве и многие другие
          города оценили нашу работу по достоинству.
        </p>
        <img style={styles.img} src={require("./assets/block2.jpg")} />
      </div>
      {/* <div>
        "Мы - компания, вдохновленная страстью к красоте и прочности кованых
        изделий. Наша миссия - создавать уникальные предметы, объединяя в них
        мастерство и эстетику. Приходите к нам, чтобы обнаружить широкий
        ассортимент изысканных решений для вашего дома и сада."
      </div> */}
    </div>
  );
}
